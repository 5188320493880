import React, { createContext, useContext, useState } from "react";
import { createQueryString } from "../../shared/function/AllFunction";
import { apiGetMethod } from "../../api/rest";
import { allApiUrl } from "../../api/apiRoute";
import { useNavigate } from "react-router-dom";

const DashboardContext = createContext();
export const DashboardProvider = ({ children }) => {
  const [selectedSSO, setSelectedSSO] = useState([]);
  const [selectedTech, setSelectedTech] = useState([]);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [sidePanelOpen, setSidePanelOpen] = React.useState(false);
  const [lastClickedCategory, setLastClickedCategory] = React.useState(null);
  const [selectedValue, setSelectedValue] = useState("");
  // const [groupedData, setGroupedData] = useState([]);
  const fetchData = async (id) => {
    setLoading(true);
    try {
      let queryObject = {
        IPRD_REFERENCE: id,
      };
      const qryStr = createQueryString(queryObject);
      const response = await apiGetMethod(
        `${allApiUrl.IPRD_REFRECE_ID}${qryStr}`
      );
      const transformedData = groupByDisplayNumber(response);
      setData(transformedData);
    } catch (err) {
      console.error(err);
      setData({ result: [] });
    } finally {
      setLoading(false);
    }
  };
  const groupByDisplayNumber = (data) => {
    const groupedData = {};

    data.forEach((item) => {
      const displayNumber = item.DIPG_DISPLAY_NUMBER;

      if (!groupedData[displayNumber]) {
        groupedData[displayNumber] = {
          DIPG_DISPLAY_NUMBER: displayNumber,
          array: [],
        };
      }

      groupedData[displayNumber].array.push(item);
    });
    return Object.values(groupedData);
  };

  return (
    <DashboardContext.Provider
      value={{
        selectedSSO,
        setSelectedSSO,
        selectedTech,
        setSelectedTech,
        data,
        loading,
        fetchData,
        setLoading,
        sidePanelOpen,
        setSidePanelOpen,
        setLastClickedCategory,
        lastClickedCategory,
        setSelectedValue,
        selectedValue
      }}
    >
      {children}
    </DashboardContext.Provider>
  );
};

export const useDashboardContext = () => {
  return useContext(DashboardContext);
};
