export const newlistTechnologyDomain = [
  {
    label: "2G",
    Tooltip: "2G",
  },
  { label: "3G", Tooltip: "3G" },
  { label: "ETSI", Tooltip: "European Telecommunications Standards Institute" },
  { label: "Telecommunications", Tooltip: "Telecommunications" },
  { label: "4G", Tooltip: "4G" },
  { label: "3GPP TS 36.211", Tooltip: "3GPP TS 36.211" },
  { label: "LTE", Tooltip: "LTE" },
  { label: "DVB", Tooltip: "DVB" },
  { label: "Mobile Communication", Tooltip: "Mobile Communication" },
  { label: "ETSI Standard", Tooltip: "ETSI Standard" },
  { label: "TS 102 690", Tooltip: "TS 102 690" },
  { label: "TS 102 921", Tooltip: "TS 102 921" },
  { label: "TS 101 404", Tooltip: "TS 101 404" },
  { label: "ES 282 003", Tooltip: "ES 282 003" },
  { label: "ES 282 004", Tooltip: "ES 282 004" },
  { label: "TS 102 832", Tooltip: "TS 102 832" },
  { label: "TS 182 027", Tooltip: "TS 182 027" },
  { label: "TR 102 489", Tooltip: "TR 102 489" },
  { label: "UMTS", Tooltip: "UMTS" },
  { label: "3GPP TS 25.309", Tooltip: "3GPP TS 25.309" },
  { label: "GSM", Tooltip: "GSM" },
  { label: "IMS", Tooltip: "IMS" },
  { label: "3GPP TS", Tooltip: "3GPP TS" },
  { label: "NFV", Tooltip: "NFV" },
  { label: "TETRA", Tooltip: "TETRA" },
  { label: "5G", Tooltip: "5G" },
  { label: "3GPP", Tooltip: "3GPP" },
  { label: "GS ZSM", Tooltip: "GS ZSM" },
  { label: "5G NR", Tooltip: "5G NR" },
  { label: "Digital Broadcasting", Tooltip: "Digital Broadcasting" },
  { label: "Standardization", Tooltip: "Standardization" },
  { label: "ORI", Tooltip: "ORI" },
  {
    label: "ATIS",
    Tooltip: "Alliance for Telecommunications Industry Solutions",
  },
  { label: "Communication", Tooltip: "Communication" },
  { label: "J-STD", Tooltip: "J-STD" },
  { label: "NR", Tooltip: "NR" },
  { label: "3GPP", Tooltip: "3rd Generation" },
  { label: "Safety", Tooltip: "Safety" },
  { label: "Wireless Communication", Tooltip: "Wireless Communication" },
  { label: "Industrial Communication", Tooltip: "Industrial Communication" },
  { label: "Data Management", Tooltip: "Data Management" },
  { label: "Data Communication", Tooltip: "Data Communication" },
  { label: "General", Tooltip: "General" },
  { label: "Financial Services", Tooltip: "Financial Services" },
  { label: "Cable and Satellite", Tooltip: "Cable and Satellite" },
  {
    label: "Various specification and revisions",
    Tooltip: "Various specification and revisions",
  },
  { label: "ANSI Standards", Tooltip: "ANSI Standards" },
  { label: "Multimedia", Tooltip: "Multimedia" },
  { label: "INCITS", Tooltip: "INCITS" },
  { label: "ISOIEC Networking", Tooltip: "ISOIEC Networking" },
  { label: "Broadcast", Tooltip: "Broadcast" },
  { label: "Networking", Tooltip: "Networking" },
  { label: "Engineering", Tooltip: "Engineering" },
  { label: "Construction", Tooltip: "Construction" },
  { label: "Waterworks", Tooltip: "Waterworks" },
  { label: "Materials", Tooltip: "Materials" },
  { label: "Digital Media", Tooltip: "Digital Media" },
  { label: "Standards", Tooltip: "Standards" },
  { label: "Manufacturing", Tooltip: "Manufacturing" },
  { label: "Welding Standards", Tooltip: "Welding Standards" },
  { label: "Electrical", Tooltip: "Electrical" },
  { label: "Electrical Standards", Tooltip: "Electrical Standards" },
  { label: "Transportation", Tooltip: "Transportation" },
  { label: "Network Protocols", Tooltip: "Network Protocols" },
  { label: "Automation", Tooltip: "Automation" },
  { label: "Technology", Tooltip: "Technology" },
  { label: "Measurement Standards", Tooltip: "Measurement Standards" },
  { label: "PH", Tooltip: "Patent Handling" },
  {
    label: "Telcommunication Standards",
    Tooltip: "Telcommunication Standards",
  },
  { label: "Standards Body", Tooltip: "Standards Body" },
  { label: "SCSI", Tooltip: "SCSI" },
  { label: "Fibre Channel", Tooltip: "Fibre Channel" },
  { label: "Information Technology", Tooltip: "Information Technology" },
  { label: "Security", Tooltip: "Security" },
  { label: "Electrical Engineering", Tooltip: "Electrical Engineering" },
  { label: "Fiber Optics", Tooltip: "Fiber Optics" },
  { label: "RFID", Tooltip: "RFID" },
  { label: "EV Charging", Tooltip: "EV Charging" },
  { label: "MPEG", Tooltip: "Moving Picture Experts Group" },
  { label: "Security and Privacy", Tooltip: "Security and Privacy" },
  { label: "IEC", Tooltip: "IEC" },
  { label: "Information Security", Tooltip: "Information Security" },
  { label: "Lighting", Tooltip: "Lighting" },
  { label: "IEC Standards", Tooltip: "IEC Standards" },
  { label: "Home Automation", Tooltip: "Home Automation" },
  { label: "Optical Communications", Tooltip: "Optical Communications" },
  {
    label: "Electromagnetic Compatibility",
    Tooltip: "Electromagnetic Compatibility",
  },
  { label: "Building Automation", Tooltip: "Building Automation" },
  { label: "Electronics Testing", Tooltip: "Electronics Testing" },
  { label: "Smart Grids", Tooltip: "Smart Grids" },
  { label: "Electrical Equipment", Tooltip: "Electrical Equipment" },
  { label: "Power Tools", Tooltip: "Power Tools" },
  { label: "Wireless", Tooltip: "Wireless" },
  { label: "WLAN", Tooltip: "WLAN" },
  { label: "Ethernet", Tooltip: "Ethernet" },
  { label: "Token Ring", Tooltip: "Token Ring" },
  { label: "Integrated Services LAN", Tooltip: "Integrated Services LAN" },
  { label: "WiMAX", Tooltip: "WiMAX" },
  { label: "Testing & Debugging", Tooltip: "Testing & Debugging" },
  { label: "Bus Interface", Tooltip: "Bus Interface" },
  { label: "circuit Design", Tooltip: "circuit Design" },
  { label: "cryptographhy", Tooltip: "cryptographhy" },
  { label: "Hardware Description", Tooltip: "Hardware Description" },
  { label: "System-On-Chip", Tooltip: "System-On-Chip" },
  { label: "Sensor Networks", Tooltip: "Sensor Networks" },
  { label: "Power Systems", Tooltip: "Power Systems" },
  { label: "Arithmetic", Tooltip: "Arithmetic" },
  { label: "Mobile", Tooltip: "Mobile" },
  { label: "Imaging", Tooltip: "Imaging" },
  { label: "Health", Tooltip: "Health" },
  { label: "General Networking", Tooltip: "General Networking" },
  { label: "CDMA", Tooltip: "CDMA" },
  { label: "TSB Standards", Tooltip: "TSB Standards" },
  { label: "TIA Standards", Tooltip: "TIA Standards" },
  { label: "Internet Standards", Tooltip: "Internet Standards" },
  { label: "3GPP TS 38.4xx", Tooltip: "3GPP TS 38.4xx" },
  { label: "3GPP TS 36.x,38.x", Tooltip: "3GPP TS 36.x,38.x" },
  { label: "3GPP TS 37.x,38.x", Tooltip: "3GPP TS 37.x,38.x" },
  { label: "3GPP TS 38.2xx", Tooltip: "3GPP TS 38.2xx" },
  { label: "3GPP TS 38.2xx,38.3xx", Tooltip: "3GPP TS 38.2xx,38.3xx" },
  { label: "3GPP TS 23.x,24.x,38.x", Tooltip: "3GPP TS 23.x,24.x,38.x" },
  { label: "3GPP TS 22.x,38.x", Tooltip: "3GPP TS 22.x,38.x" },
  { label: "3GPP TS 38.3xx", Tooltip: "3GPP TS 38.3xx" },
  { label: "3GPP TS 36.x,37.x,38.x", Tooltip: "3GPP TS 36.x,37.x,38.x" },
  { label: "3GPP TS 24.x,38.x", Tooltip: "3GPP TS 24.x,38.x" },
  { label: "5G Core", Tooltip: "5G Core" },
  { label: "EPC", Tooltip: "EPC" },
  { label: "3GPP TS 38.x", Tooltip: "3GPP TS 38.x" },
  { label: "3GPP TS 38.x,37.x", Tooltip: "3GPP TS 38.x,37.x" },
  { label: "3GPP", Tooltip: "3rd Generation Partnership Project" },
  { label: "Mobile Core Network", Tooltip: "Mobile Core Network" },
  { label: "oneM2M", Tooltip: "oneM2M" },
  { label: "UMTS", Tooltip: "Universal Mobile Telecommunication System" },
  { label: "TTA", Tooltip: "Telecommunication Technology Association" },
  { label: "3GPP2", Tooltip: "3GPP2" },
  { label: "3G Communication", Tooltip: "3G Communication" },
  { label: "3G and LTE Technology", Tooltip: "3G and LTE Technology" },
  { label: "3G Technology", Tooltip: "3G Technology" },
  { label: "LTE", Tooltip: "Long Term Evolution" },
  { label: "TTA 3G", Tooltip: "TTA 3G" },
  { label: "3GPP Standard", Tooltip: "3GPP Standard" },
  { label: "MPEG", Tooltip: "MPEG" },
  { label: "3GPP LTE", Tooltip: "3GPP LTE" },
  { label: "GSM", Tooltip: "Global system form Mobile Communication" },
  { label: "Real-Time Locating", Tooltip: "Real-Time Locating" },
  { label: "TTAE 3G", Tooltip: "TTAE 3G" },
  { label: "Control Networking", Tooltip: "Control Networking" },
  { label: "Image Processing", Tooltip: "Image Processing" },
  { label: "Telcommunication (3G)", Tooltip: "Telcommunication (3G)" },
  { label: "TTA", Tooltip: "TTA" },
  { label: "Radio Access Network", Tooltip: "Radio Access Network" },
  { label: "3GPP2 TS", Tooltip: "3GPP2 TS" },
  { label: "3G-X", Tooltip: "3G-X" },
  { label: "UMB", Tooltip: "UMB" },
  { label: "TTAT", Tooltip: "TTAT" },
  { label: "3GPP,LTE", Tooltip: "3GPP,LTE" },
  { label: "LTE-Advanced", Tooltip: "LTE-Advanced" },
  { label: "Medical Devices", Tooltip: "Medical Devices" },
  { label: "Data Storage", Tooltip: "Data Storage" },
  { label: "Aerospace", Tooltip: "Aerospace" },
  { label: "Industrial Engineering", Tooltip: "Industrial Engineering" },
  { label: "Quality Assurance", Tooltip: "Quality Assurance" },
  { label: "Environmental Testing", Tooltip: "Environmental Testing" },
  { label: "Network Cabling", Tooltip: "Network Cabling" },
  { label: "Materials Science", Tooltip: "Materials Science" },
  { label: "Product Testing", Tooltip: "Product Testing" },
  { label: "Automotive", Tooltip: "Automotive" },
  { label: "Geographic Information", Tooltip: "Geographic Information" },
  { label: "Radio Frequency ID", Tooltip: "Radio Frequency ID" },
  { label: "Optical Technology", Tooltip: "Optical Technology" },
  { label: "Localization Systems", Tooltip: "Localization Systems" },
  { label: "Smart Cards", Tooltip: "Smart Cards" },
  { label: "Near Field Communication", Tooltip: "Near Field Communication" },
  { label: "Biometrics", Tooltip: "Biometrics" },
  { label: "Water Quality", Tooltip: "Water Quality" },
  { label: "Document Management", Tooltip: "Document Management" },
  { label: "Agriculture and Machinery", Tooltip: "Agriculture and Machinery" },
  { label: "Plumbing", Tooltip: "Plumbing" },
  { label: "Cleanroom Technology", Tooltip: "Cleanroom Technology" },
  { label: "Coatings", Tooltip: "Coatings" },
  { label: "Data Center", Tooltip: "Data Center" },
  { label: "Data Security", Tooltip: "Data Security" },
  { label: "Iamge and video Coding", Tooltip: "Iamge and video Coding" },
  {
    label: "Radio Frequency Identification",
    Tooltip: "Radio Frequency Identification",
  },
  { label: "Directory Services", Tooltip: "Directory Services" },
  { label: "Identification Systems", Tooltip: "Identification Systems" },
  { label: "ISO Standard", Tooltip: "ISO Standard" },
  { label: "Smart Homes", Tooltip: "Smart Homes" },
  { label: "Mobile Technology", Tooltip: "Mobile Technology" },
  { label: "OMA-ER", Tooltip: "OMA-ER" },
  { label: "OMA-TS-ULP", Tooltip: "OMA-TS-ULP" },
  { label: "OMA-TS-DM", Tooltip: "OMA-TS-DM" },
  { label: "OMA-TS-CPNS-Core", Tooltip: "OMA-TS-CPNS-Core" },
  { label: "OMA-TS-SACMO", Tooltip: "OMA-TS-SACMO" },
  { label: "OMA TS", Tooltip: "Open Mobile Alliance" },
  { label: "OMA TS", Tooltip: "OMA Ts" },
  { label: "Devices Management", Tooltip: "Devices Management" },
  { label: "OMA-TS-DS", Tooltip: "OMA-TS-DS" },
  { label: "OMA Advertising", Tooltip: "OMA Advertising" },
  { label: "OMA Mobile Advertising", Tooltip: "OMA Mobile Advertising" },
  {
    label: "Push-to-Talk over Cellular",
    Tooltip: "Push-to-Talk over Cellular",
  },
  { label: "OMA DCD", Tooltip: "OMA DCD" },
  { label: "Location Services", Tooltip: "Location Services" },
  { label: "OMA-TS-SCE", Tooltip: "OMA-TS-SCE" },
  { label: "OMA-TS-BCAST", Tooltip: "OMA-TS-BCAST" },
  {
    label: "Localized Application and Widget Management Object",
    Tooltip: "Localized Application and Widget Management Object",
  },
  { label: "OMA-TS-DRM", Tooltip: "OMA-TS-DRM" },
  { label: "Presence Management", Tooltip: "Presence Management" },
  { label: "Charging and Billing", Tooltip: "Charging and Billing" },
  { label: "OMA RD", Tooltip: "Reference Document" },
  { label: "Mobile Broadcast Services", Tooltip: "Mobile Broadcast Services" },
  { label: "Presence Services", Tooltip: "Presence Services" },
  { label: "Converged IP Messagining", Tooltip: "Converged IP Messagining" },
  {
    label: "User Plane Location Protocol",
    Tooltip: "User Plane Location Protocol",
  },
  { label: "DRM", Tooltip: "DRM" },
  { label: "WAP", Tooltip: "Wireless Application Protocol" },
  { label: "Instant Messagining", Tooltip: "Instant Messagining" },
  { label: "Data Synchronization", Tooltip: "Data Synchronization" },
  {
    label: "Location and Friends Connectivity",
    Tooltip: "Location and Friends Connectivity",
  },
  { label: "Mobile Location Services", Tooltip: "Mobile Location Services" },
  {
    label: "Key Performance Indicators",
    Tooltip: "Key Performance Indicators",
  },
  { label: "Mobile Advertising", Tooltip: "Mobile Advertising" },
  { label: "Digital Rights Management", Tooltip: "Digital Rights Management" },
  { label: "CPNS", Tooltip: "CPNS" },
  { label: "WAP", Tooltip: "WAP" },
  {
    label: "Secure Content Identification and Management",
    Tooltip: "Secure Content Identification and Management",
  },
  {
    label: "Services Request Management",
    Tooltip: "Services Request Management",
  },
  { label: "Spam Reporting", Tooltip: "Spam Reporting" },
  { label: "Gateway Management Object", Tooltip: "Gateway Management Object" },
  { label: "OMA Standard", Tooltip: "OMA Standard" },
  { label: "OMA MMS", Tooltip: "OMA MMS" },
  { label: "OMA-RD-DCMO", Tooltip: "OMA-RD-DCMO" },
  { label: "OMA-TS-Poc", Tooltip: "OMA-TS-Poc" },
  { label: "DRM", Tooltip: "Digital Rights Managment" },
  { label: "PoC", Tooltip: "PoC" },
  { label: "SyncML", Tooltip: "SyncML" },
  { label: "Digital Rights Management", Tooltip: "Digital Rights Management" },
  { label: "DCD", Tooltip: "DCD" },
  { label: "OMA", Tooltip: "Open Mobile Alliance" },
  { label: "OMA-TS-MLP", Tooltip: "OMA-TS-MLP" },
  { label: "OMA Device Managment", Tooltip: "OMA Device Managment" },
  {
    label: "Mobile Communication Standard",
    Tooltip: "Mobile Communication Standard",
  },
  { label: "Wireless Village", Tooltip: "Wireless Village" },
  {
    label: "Synchronization Markup Language",
    Tooltip: "Synchronization Markup Language",
  },
  { label: "OMA-WAP", Tooltip: "OMA-WAP" },
  {
    label: "Multimedia Messagining Service",
    Tooltip: "Multimedia Messagining Service",
  },
  { label: "OMA-RD-Multimodal", Tooltip: "OMA-RD-Multimodal" },
  {
    label: "Instant Messagining and Presence Service",
    Tooltip: "Instant Messagining and Presence Service",
  },
  { label: "ULP", Tooltip: "User Location Protocol" },
  { label: "DM", Tooltip: "Device Managment" },
  {
    label: "SIMPLE",
    Tooltip: "SIP for Instant Messaging and Presence Leveraging Extensions",
  },
  { label: "syncML", Tooltip: "Synchronization Markup Lanaguage" },
  { label: "DS", Tooltip: "Device Synchronization" },
  { label: "DiagMon", Tooltip: "Diagnostic Monitoring" },
  { label: "RLP", Tooltip: "Reliable Protocol" },
  { label: "BCAST", Tooltip: "Broadcast" },
  { label: "GPS", Tooltip: "GPS" },
  {
    label: "OMA Technical Specifications",
    Tooltip: "OMA Technical Specifications",
  },
  { label: "OMA", Tooltip: "OMA" },
  { label: "LPPe", Tooltip: "Location Privacy Protovol enhanced" },
  { label: "CPM", Tooltip: "Content Protection Module" },
  { label: "MLP", Tooltip: "Mobile Location Protocol" },
  { label: "OMA-TS-LPPe", Tooltip: "OMA-TS-LPPe" },
  {
    label: "OMA Secure User Plane Location",
    Tooltip: "OMA Secure User Plane Location",
  },
  {
    label: "Secure User Plane Location",
    Tooltip: "Secure User Plane Location",
  },
  { label: "Charging", Tooltip: "Charging" },
  { label: "Charging and DLOTA", Tooltip: "Charging and DLOTA" },
  { label: "DLOTA", Tooltip: "DLOTA" },
  { label: "Broadcast Services", Tooltip: "Broadcast Services" },
  { label: "OMA RD", Tooltip: "OMA RD" },
  { label: "OMA BCMCS", Tooltip: "OMA BCMCS" },
  { label: "Broadcast Adaptation", Tooltip: "Broadcast Adaptation" },
  { label: "Broadcast Distribution", Tooltip: "Broadcast Distribution" },
  { label: "OMA Standard", Tooltip: "OMA Standard" },
  { label: "OMA-TS-DiagMon", Tooltip: "OMA-TS-DiagMon" },
  {
    label: "Secure User Plane Location and ULP",
    Tooltip: "Secure User Plane Location and ULP",
  },
  { label: "OMA PCPS", Tooltip: "OMA PCPS" },
  { label: "Standard Document", Tooltip: "Standard Document" },
  { label: "Korean Standard", Tooltip: "Korean Standard" },
  { label: "ITU-T", Tooltip: "ITU-T" },
  { label: "TTAE IT", Tooltip: "TTAE IT" },
  { label: "Technical Specification", Tooltip: "Technical Specification" },
  {
    label: "Wireless Local Area Network",
    Tooltip: "Wireless Local Area Network",
  },
  { label: "IT Standard", Tooltip: "IT Standard" },
  { label: "TTAE ET", Tooltip: "TTAE ET" },
  { label: "TR", Tooltip: "TR" },
  { label: "DMIS", Tooltip: "DMIS" },
  { label: "CAM", Tooltip: "CAM" },
  { label: "ISO", Tooltip: "ISO" },
  { label: "IEC MPEG", Tooltip: "IEC MPEG" },
  { label: "TIA", Tooltip: "TIA" },
  { label: "EIA Standards", Tooltip: "EIA Standards" },
  {
    label: "IEC Information Technology",
    Tooltip: "IEC Information Technology",
  },
  { label: "IEC RFID", Tooltip: "IEC RFID" },
  { label: "EDGE", Tooltip: "EDGE" },
  { label: "OMA Advertising", Tooltip: "OMA Advertising" },
  {
    label: "OMA Technical Specification",
    Tooltip: "OMA Technical Specification",
  },
  { label: "OMA Download", Tooltip: "OMA Download" },
  { label: "DRM", Tooltip: "Digital Rights Managment" },
  { label: "OMA DM", Tooltip: "OMA DM" },
  { label: "CP", Tooltip: "CP" },
  {
    label: "OMA Research & Development",
    Tooltip: "OMA Research & Development",
  },
  { label: "OMA ERP", Tooltip: "OMA ERP" },
  { label: "OMA SUPL", Tooltip: "OMA SUPL" },
  { label: "OMA WAP", Tooltip: "OMA WAP" },
  {label:"3GPP TS 38",Tooltip:"3GPP TS 38"},
  {label:"3GPP TS 26",Tooltip:"3GPP TS 26"},
  {label:"Core Network",Tooltip:"Core Network"},
  {label:"3GPP Security",Tooltip:"3GPP Security"},
  {label:"3GPP TS 28",Tooltip:"3GPP TS 28"},
  {label:"Operations",Tooltip:"Operations"},
  {label:"Network",Tooltip:"Network"},
  {label:"ISO/IEC",Tooltip:"ISO/IEC"},
  {label:"Industrial",Tooltip:"Industrial"},
  {label:"ITU-R",Tooltip:"ITU-R"},
  {label:"Optical",Tooltip:"Optical"},
  {label:"IETF",Tooltip:"IETF"},
  {label:"WPKI",Tooltip:"WPKI"},
  {label:"Electronics",Tooltip:"Electronics"},
  {label:"IEEE",Tooltip:"IEEE"},
  {label:"Precision Time Protocol",Tooltip:"Precision Time Protocol"}
];
export const listSSO = [
  {
    label: "ETSI",
    Tooltip: "European Telecommunications Standards Institute",
  },
  {
    label: "ATIS",
    Tooltip: "Alliance for Telecommunications Industry Solutions",
  },
  { label: "CCSA", Tooltip: "China Communications Standards Association" },
  { label: "ANSI", Tooltip: "American National Standards Institute" },
  { label: "IEC", Tooltip: "International Electrotechnical Commission" },
  {
    label: "IEEE",
    Tooltip: "IEEE	Institute of Electrical and Electronics Engineers",
  },
  { label: "TIA", Tooltip: "Telecommunications Industry Association" },
  { label: "IETF", Tooltip: "IETF	Internet Engineering Task Force" },
  { label: "IMT", Tooltip: "International Mobile Telecommunications" },
  {
    label: "ITU-R",
    Tooltip:
      "International Telecommunication Union - Radiocommunication Sector",
  },
  {
    label: "ITU-T",
    Tooltip:
      "International Telecommunication Union - Telecommunication Standardization Sector",
  },
  { label: "TTA", Tooltip: "Telecommunications Technology Association" },
  { label: "One M2M", Tooltip: "One Machine-to-Machine" },
  { label: "ISO", Tooltip: "International Organization for Standardization" },
  { label: "OMA", Tooltip: "Open Mobile Alliance" },
];

export const headCells = [
  { id: "standard", label: "Standard/Specification" },
  { id: "technology", label: "Technology" },
  { id: "sub-tech", label: "Sub Technology" },
  { id: "version", label: "Recommendation/Committee" },
  { id: "part", label: "Illustrative Part" },
];
export const headCells2 = [
  { id: "Work Item / Standard No.", label: "Application Number" },
  { id: "pub_no", label: "Publication Number" },
  { id: "patent_no", label: "Patent Number" },
  { id: "Title", label: "Title" },
  { id: "Inventor", label: "Inventor(S)" },
  { id: "owner_name", label: "Patent Owner" },
  { id: "Current_Assignee", label: "Current Assignee" },
  { id: "Country_code", label: "Country Code" },
];

export const headCells3 = [
  { id: "Signature Date", label: "Signature Date" },
  // { id: "ES to Project", label: "ES to Project" },
  // { id: "ES to standard", label: "ES to Standard" },
  { id: "Original App/Pub/Parent No.", label: "Original Number" },
  { id: "DIPG EX ID", label: "DIPG EX ID" },
  { id: "PAT ID", label: "PAT ID" },
  { id: "DIPGPATF ID", label: "DIPGPATF ID" },
  { id: "DIPG ID", label: "DIPG ID" },
];
